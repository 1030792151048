import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './global.css';

import './assets/fonts/fonts';
import { App } from './App';
import { store } from './store/store';

const el = document.getElementById('root');
if (el === null) throw new Error('Root container missing in index.html');

const root = ReactDOM.createRoot(el);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import app from './slices/appSlice';
import * as effects from './effects';

const apiReducers = {};
const apiMiddleware = [];

for (const key in effects) {
    if (!/api$/i.test(key)) continue;
    const { reducerPath, reducer, middleware } = effects[key]; // eslint-disable-line import/namespace
    apiReducers[reducerPath] = reducer;
    apiMiddleware.push(middleware);
}

const reducer = combineReducers({ app, ...apiReducers });

const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...apiMiddleware /*, effects.handleApiError */) //
});

export { store };

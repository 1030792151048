/*eslint-disable*/
import React from 'react';
import { Link } from '@reach/router';
import warehouse from '../assets/img/warehouse.jpg';

import { Navbar } from '../components';
// import Footer from '~components/Footers/Footer.js';

const Landing = () => {
    return (
        <>
                    <Navbar fixed />

                    <main>
            <section className="relative items-center h-screen pt-20 mx-auto header max-h-860-px">
            <div class="mb-8 p-2 w-full flex flex-wrap">
      <div class="w-full lg:w-1/2 ">
      <h2 className="text-4xl font-semibold text-sky-500 dark:text-sky-400">
                            Connects the global agricultural powerhouse and digitally empowered society
                        </h2>
                        <p className="mt-4 text-lg leading-relaxed text-sky-500">
                            Buy directly from independent farmers, small shops and producers.{' '}
                        </p>

                        <div className="mt-12">
                            <a
                                href="#explore"
                                target="_blank"
                                className="px-6 py-4 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-pink-700 rounded shadow outline-none get-started focus:outline-none active:bg-pink-800 hover:shadow-lg"
                            >
                                Explore
                            </a>
                            </div>
      </div>
      <div class="w-full  relative flex lg:w-1/2">
      <img
                            src={warehouse}
                            alt="..."
                        />
      </div>
   </div>

            </section>
      
          </main>
        </>
    );
};

export { Landing };

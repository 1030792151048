import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searching: false,
    hideNav: false,
    searchQuery: '',
    assetsSearchResults: null,
    backScrollValues: [], // Y scroll values
    error: null
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showSearch(state) {
            state.searching = true;
        },
        hideSearch(state) {
            state.searching = false;
        }
    }
});

export const { showSearch, hideSearch } = appSlice.actions;

export default appSlice.reducer;

import React from 'react';

import { Router } from './Router';

const App = () => {
    return (
        <div>
            <Router />
        </div>
    );
};

export { App };

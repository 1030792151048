import React from 'react';
import { Router, Location } from '@reach/router';
import { AnimatePresence } from 'framer-motion';

const FramerRouter = ({ children, outerChildren }) => (
    <Location>
        {({ location }) => (
            <div className="relative h-full">
                {outerChildren && outerChildren({ location })}
                <AnimatePresence exitBeforeEnter>
                    <Router key={location.key} location={location}>
                        {children}
                    </Router>
                </AnimatePresence>
            </div>
        )}
    </Location>
);

export { FramerRouter };

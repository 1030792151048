import React from 'react';

import { FramerRouter } from './FramerRouter';
import { Landing } from './pages';

const Router = () => (
    <FramerRouter>
        <Landing path="/" />
    </FramerRouter>
);

export { Router };

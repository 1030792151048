import romanWoff2 from './HaasGrotTextR-55Roman.woff2';
import romanWoff from './HaasGrotTextR-55Roman.woff';
import romanTtf from './HaasGrotTextR-55Roman.ttf';
import italicWoff2 from './HaasGrotTextR-56Italic.woff2';
import italicWoff from './HaasGrotTextR-56Italic.woff';
import italicTtf from './HaasGrotTextR-56Italic.ttf';
import mediumWoff2 from './HaasGrotTextR-65Medium.woff2';
import mediumWoff from './HaasGrotTextR-65Medium.woff';
import mediumTtf from './HaasGrotTextR-65Medium.ttf';

(() => {
    const css = `
        @font-face {
            font-family: 'Haas Grot Text';
            src: url(${romanWoff2}) format('woff2'), url(${romanWoff}) format('woff'),
                url(${romanTtf}) format('truetype');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Haas Grot Text';
            src: url(${italicWoff2}) format('woff2'), url(${italicWoff}) format('woff'),
                url(${italicTtf}) format('truetype');
            font-weight: normal;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Haas Grot Text';
            src: url(${mediumWoff2}) format('woff2'), url(${mediumWoff}) format('woff'),
                url(${mediumTtf}) format('truetype');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        } `;

    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';

    if (style.styleSheet) {
        // This is required for IE8 and below
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
})();

/*eslint-disable*/
import React from 'react';
import { Link } from '@reach/router';
import logo from '../../assets/img/logo_adv.png';

const Navbar = props => {
    return (
        <>
            <nav className="fixed top-0 z-50 flex flex-wrap items-center justify-between w-full bg-white shadow navbar-expand-lg">
                <div className="container flex flex-wrap items-center justify-between mx-auto">
                    <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
                        <img className="w-14 h-14" src={logo} alt="..." />
                    </div>
                </div>
            </nav>
        </>
    );
};

export { Navbar };
